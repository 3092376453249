import React from "react";
import { Box, Typography } from "@mui/material";
import { AngleBracket } from "assets/icons/index.js";
import { useStyles } from "./styles.js";
import { Identification } from "assets/icons"
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import style from "./index.module.scss"


export const Cards = ({ registros, handleClick }) => {
    console.log(registros)
    const styles = useStyles();
    const onClick = (merchantId, title) => {
        if (typeof handleClick === "function") {
            const data = {
                merchantId,
                title: title,
            }
            return handleClick(data)
        } else {
            return
        }
    }
    return (
        <>
            {registros.map((registro, i) => {
                const { registroXMerchantId, registroNombre, estadoRegistroCod, usuarioNombre } = registro;
                return (
                    <Box key={i} display={"flex"} sx={{ cursor: "pointer" }} height={"60px"} width={"600px"} border={"2px"} boxShadow={"0px 1px 5px 0px rgba(153,153,153,0.7)"} borderRadius={"10px"} onClick={(e) => onClick(registroXMerchantId, registroNombre)} className={`${styles.root} ${estadoRegistroCod === "A" ? style.green : estadoRegistroCod === "E" ? style.yellow : style.red}`}  >
                        <div style={{ display: "flex", width: "60%" }}>
                            <Box display="flex" alignItems={"center"} marginRight={2} marginLeft={2} width={"50px"}>
                                <img src={Identification} alt="logo" className={styles.imgComerciosDefault} />
                            </Box>

                            <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} width={"60%"}>
                                <Typography variant="h1" className={styles.title}>{registroNombre}</Typography>
                                <Typography variant="subtitle1" className={styles.subtitle}>{"Verificar datos del comercio"}</Typography>
                            </Box>
                        </div>
                        <Box display="flex" flexDirection={"column"} alignItems={"end"} justifyContent={"center"} marginLeft={0} marginRight={2}>
                            <Typography variant="subtitle1" className={styles.subtitle}>{usuarioNombre}</Typography>
                            {estadoRegistroCod === "A" ?
                                <div className={`${style.icon} ${style.check}`}>
                                    <CheckRoundedIcon />
                                </div> :
                                estadoRegistroCod === "R" ?
                                    <div className={`${style.icon} ${style.cancel}`}>
                                        <CloseRoundedIcon />
                                    </div> :
                                    estadoRegistroCod === "E" ?
                                        <div className={`${style.icon} ${style.hourglass}`}>
                                            <HourglassBottomRoundedIcon />
                                        </div> :
                                        <div className={`${style.icon}`}>
                                            <PriorityHighRoundedIcon />
                                        </div>}
                        </Box>
                    </Box>)
            })}
        </>
    )
}