import { Grid } from '@mui/material';
import axios from 'axios';
import { Button } from 'components';
import { DropZone } from 'components/DropZone';
import React, { useState } from 'react'
import { useRecoilValue } from 'recoil';
import { userCredentials } from 'recoilState/GlobalState';
import { DocumentosPut } from 'services/hooli-services/DocumentosPut';
import { MerchantBOBAUpd } from 'services/hooli-services/MerchantBOBAUpd';
import { MerchantBOFilesDo } from 'services/hooli-services/MerchantBoFilesDo';

export const UltimoBalanceYActa = ({ merchantInfo, load, setLoad, setModalDetails, modalDetails, location }) => {
    const credentials = useRecoilValue(userCredentials);
    const [updFile, setUpdFile] = useState(false);
    const [file, setFile] = useState(null)
    const handleUpd = async () => {
        setLoad(true)
        const response = await MerchantBOBAUpd(credentials, {
            merchantId: merchantInfo.merchantId,
            estadoRegistroCod: "A"
        }, location)
        setLoad(false)
        setModalDetails({ ...modalDetails, isOpen: false })
    }
    const handleDecline = async () => {
        setLoad(true)
        const response = await MerchantBOBAUpd(credentials, {
            merchantId: merchantInfo.merchantId,
            estadoRegistroCod: "R"
        }, location)
        setLoad(false)
        setModalDetails({ ...modalDetails, isOpen: false })
    }
    const handleReadFile = async (tipo) => {
        const data = {
            fileName: `${merchantInfo.merchantCUIT.replaceAll('-', '')}_${tipo}.pdf`,
            code: tipo,
            cuit: merchantInfo.merchantCUIT.replaceAll('-', ''),
            action: "R"
        }
        const response = await MerchantBOFilesDo(credentials, data)
        if (response.status.code === 1 && response.result[0].url) {
            const { data } = await axios.get(response.result[0].url, {
                headers: {
                    'Accept': 'application/pdf',
                    "Content-Type": "application/octet-stream"
                },
                responseType: "blob",
                data: {}
            })
            let urlBlob = window.URL.createObjectURL(new Blob([data]));
            let a = document.createElement('a');
            a.href = urlBlob;
            a.download = `${merchantInfo.merchantCUIT.replaceAll('-', '')}${tipo}.pdf`; // Nombre del archivo
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }

    const handleUpdFile = async (tipo) => {
        const data = {
            fileName: `${merchantInfo.merchantCUIT.replaceAll('-', '')}_${tipo}.pdf`,
            code: tipo,
            cuit: merchantInfo.merchantCUIT.replaceAll('-', ''),
            action: "U"
        }
        const response = await MerchantBOFilesDo(credentials, data)
        if (response.status.code === 1) {
            // console.log("File updated", file[0])
            await DocumentosPut(response.result[0].url, file[0])
            setUpdFile(false)
            setFile(null)
        }
    }
    return (
        <>
            {updFile ? <div style={{ display: "flex", flexDirection: "column", gap: "8px", alignItems: "center" }}>
                <DropZone file={file} setFile={setFile} />
                <Grid item display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
                    <Button text={"Subir"} onClick={() => handleUpdFile(updFile)} />
                    <Button text={"Cancelar"} onClick={() => setUpdFile(false)} />
                </Grid>
            </div> : <><div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                <Grid item display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
                    <Button text={"Descargar Balance"} onClick={() => handleReadFile("UB")} />
                    <Button text={"Subir Balance"} onClick={() => setUpdFile("UB")} />
                </Grid>
                <Grid item display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
                    <Button text={"Descargar aprobacion"} onClick={() => handleReadFile("AB")} />
                    <Button text={"Subir aprobacion"} onClick={() => setUpdFile("AB")} />
                </Grid>
            </div>
                <div>
                    <Button text={"Confirmar"} onClick={() => handleUpd()} disabled={load} />
                    <Button text={"Rechazar"} onClick={() => handleDecline()} disabled={load} />
                    <Button text={"Cerrar"} onClick={() => setModalDetails({ ...modalDetails, confirmationModalOpen: true })} />
                </div></>}
        </>
    )
}
