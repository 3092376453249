import axios from "axios";
import { serviceURL } from "../../consts/ServiceURL";
import { APLICACION_COD, X_API_KEY } from "consts/env";
import { fallbackURL } from "consts/FallbackURL";

export default async function axiosPost(
    body,
    credentials = false
) {
    const headers = {
        "Content-Type": "application/json",
        "x-hooli-bridge" : "Y"
    };

    if (credentials) {
        headers["x-hooli-token"] = credentials.hooliToken || credentials.token;
        headers["x-session-id"] = credentials.sessionId;
    }
    
    headers["x-api-key"] = X_API_KEY;
    headers["x-origin-id"] = APLICACION_COD;

    try {
        const { data: result } = await axios.post(serviceURL, body, { headers });
        if (!result || result === null || result === undefined) {
            return {
                status: {
                    code: 0,
                    errcode: 99999,
                    errmsg: "Error inesperado en request",
                    action: "U",
                    id: null,
                },
                result: [],
            };
        }

        if (result?.status?.code !== 1 && result?.status?.errcode === 10000) {
            localStorage.clear();
            return window.location.assign(fallbackURL);
        }

        return result;
    } catch (err) {
        return {
            status: {
                code: 0,
                errcode: err.response ? err.response.status : 0,
                errmsg: err.message,
                action: "U",
                id: null,
            },
            result: [],
        };
    }
}
