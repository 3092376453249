import React, { useEffect, useState } from 'react'
import Filters from "components/Filters";
import { useFormik } from 'formik';
import XGridDemo from "components/UI/Grid/XGrid";
import { comercios as titles } from 'consts/titulos-de-tablas';
import { Buttons, TransitionModal } from "components";
import { HeaderButton } from "components";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { routeInfo, rowSelected, userCredentials, userInfo } from 'recoilState/GlobalState';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box } from '@mui/material';
import tableStyles from "styles/pages/Tables.module.scss";
import { UsuarioBOGet } from 'services/hooli-services/UsuarioBOGet';
import { useNavigate } from 'react-router-dom';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { MerchantBOAsignacionIns } from 'services/hooli-services/MerchantBOAsignacionIns';
import { MerchantBOGet } from 'services/hooli-services/MerchantBoGet';
import { MerchantOrigenGet } from 'services/hooli-services/MerchantOrigenGet';
const estadoOperativo = {
    "O": "Operativo",
    "B": "Baja",
    "F": "Backoffice",
    "S": "Suspendido"
}


function Comercios() {
    const [loading, setLoading] = useState(false);
    const [rows, setRows] = useState([])
    const [responsables, setResponsables] = useState([]);
    const xgridRowSelected = useRecoilValue(rowSelected);
    const credentials = useRecoilValue(userCredentials);
    const userLoged = useRecoilValue(userInfo);
    const [origen, setOrigen] = useState([])
    const setRoute = useSetRecoilState(routeInfo);
    const navigate = useNavigate();
    // Hooks 
    useEffect(() => {
        setRoute({
            wordKey: "Backoffice Comercios"
        });
        Promise.all([getMerchants(), getResponsables(), getOrigen()]);

    }, []);
    const formik = useFormik({
        initialValues: {
            cuit: "",
            razonSocial: "",
            responsable: "",
            estadoOperativo: "",
            origen: "",
            fechaDesde: "",
            fechaHasta: "",
            estadoRegistro: "NOK"
        },
        onSubmit: (values) => {
            getMerchants();
        },
    });
    const getMerchants = async (values) => {
        setLoading(true);
        const data = {
            merchantCUIT: formik.values.cuit,
            merchantNombre: formik.values.razonSocial,
            fechaDesde: formik.values.fechaDesde,
            fechaHasta: formik.values.fechaHasta,
            merchantStatusOperativo: formik.values.estadoOperativo === "Todos" ? null : formik.values.estadoOperativo,
            merchantStatusRegistro: formik.values.estadoRegistro || null,
            merchantBoAsignacionUsuarioId: formik.values.responsable === "Todos" ? null : formik.values.responsable,
            merchantOrigenCod: formik.values.origen === "Todos" ? null : formik.values.origen,

        }
        const response = await MerchantBOGet(credentials, data);
        const mapResponse = response.result.map((merchant) => {
            return {
                id: merchant.merchantId,
                merchantStatusRegistro: merchant.merchantStatusRegistro,
                merchantBOAsignacionUsuarioId: merchant?.merchantBOAsignacionUsuarioId,
                [titles[0]]: merchant.merchantCUIT,
                [titles[1]]: merchant.merchantRZ,
                [titles[2]]: merchant.merchantNombre,
                [titles[3]]: merchant.merchantPFPJ,
                [titles[4]]: merchant.merchantFechaAlta,
                [titles[5]]: merchant.merchantOrigenNombre,
                [titles[6]]: estadoOperativo[merchant.merchantStatusOperativo],
                [titles[7]]: merchant.merchantStatusRegistro,
                [titles[8]]: merchant.merchantBOAsignacionNombre,
                [titles[9]]: merchant.merchantBOAsignacionFH
            }
        })
        setRows(mapResponse);
        setLoading(false);
    }

    const getResponsables = async () => {
        const response = await UsuarioBOGet(credentials);
        const mapResponse = response.result.map((e) => {
            return {
                id: e.usuarioId,
                label: e.usuarioApelNom
            }
        })
        setResponsables(mapResponse);

    }

    const getOrigen = async () => {
        const response = await MerchantOrigenGet(credentials);
        const mapResponse = response.result.map((e) => {
            return {
                id: e.merchantOrigenCod,
                label: e.merchantOrigenNombre
            }
        });
        setOrigen(mapResponse);
    }



    const handleClick = () => {
        navigate(`/comercios-registros?merchantId=${xgridRowSelected.row.id}`)
    }

    const handleClickAsign = async (type) => {
        let data = null;
        if (type === "des") {
            data = {
                merchantId: xgridRowSelected.row.id,
                status: "B"
            }
        } else {
            data = {
                merchantId: xgridRowSelected.row.id,
                status: "A"
            }
        }

        const response = await MerchantBOAsignacionIns(credentials, data);
        getMerchants();
    }

    return (
        <section className={tableStyles.section}>
            <Box display={"flex"} flexWrap={"wrap"} justifyContent={"end"} mb={2} maxWidth={"100%"}>
                <Filters
                    formik={formik}
                    inputsRender={[
                        { type: "text", name: "cuit", label: "CUIT", disabled: false },
                        { type: "text", name: "razonSocial", label: " Razon Social o nombre fantasia", disabled: false },
                        { type: "date", name: "fechaDesde", label: "Fecha desde", disabled: false },
                        { type: "date", name: "fechaHasta", label: "Fecha hasta", disabled: false },
                        { type: "select", name: "responsable", label: "Responsable", disabled: false, options: [{ id: null, label: "Todos" }, ...responsables], firstOptionKey: "id", firstOptionName: "label", firstOptionValue: "id" },
                        { type: "select", name: "estadoOperativo", label: "Estado Operativo ", disabled: false, options: [{ id: null, label: "Todos" }, { id: "O", label: "Operativo" }, { id: "B", label: "Baja" }, { id: "S", label: "Suspendido" }, { id: "F", label: "Backoffice" }], firstOptionKey: "id", firstOptionName: "label", firstOptionValue: "id" },
                        { type: "select", name: "estadoRegistro", label: "Estado Registro ", disabled: false, options: [{ id: null, label: "OK" }, { id: "NOK", label: "NO OK" }], firstOptionKey: "id", firstOptionName: "label", firstOptionValue: "id" },
                        { type: "select", name: "origen", label: "Origen ", disabled: false, options: [{ id: null, label: "Todos" }, ...origen], firstOptionKey: "id", firstOptionName: "label", firstOptionValue: "id" },
                    ]}>
                    <Buttons filtersApplied={true}>
                        {
                            xgridRowSelected.row?.merchantBOAsignacionUsuarioId === userLoged?.usuarioId ?
                                (

                                    <HeaderButton
                                        disabled={!xgridRowSelected.row?.merchantBOAsignacionUsuarioId}
                                        text={"Quitar asignación"}
                                        onClick={() => handleClickAsign("des")}

                                    >
                                        <PersonRemoveOutlinedIcon />
                                    </HeaderButton>
                                ) : (
                                    <HeaderButton
                                        disabled={xgridRowSelected.status !== true}
                                        text={"Asignar "}
                                        onClick={() => handleClickAsign("as")}

                                    >
                                        <PersonAddAltOutlinedIcon />
                                    </HeaderButton>
                                )
                        }
                        <HeaderButton
                            disabled={xgridRowSelected.status !== true}
                            text={"Ver registros "}
                            onClick={() => handleClick()}
                        >
                            <AccountCircleIcon />
                        </HeaderButton>
                    </Buttons>
                </Filters>
            </Box>
            <XGridDemo
                titles={titles}
                rows={rows}
                loading={loading}
            />
        </section>
    )
}

export default Comercios; 
