import React, { useEffect, useState } from 'react'
import { useStyles } from './styles';
import * as yup from "yup";
import { Box, Grid } from '@mui/material';
import { FormikSelectInput, FormikTextInput } from 'components/Filters/FormikInputs';
import { useFormik } from 'formik';
import { Button } from 'components';
import { MerchantBOFilesDo } from 'services/hooli-services/MerchantBoFilesDo';
import { useRecoilValue } from 'recoil';
import { userCredentials } from 'recoilState/GlobalState';
import axios from 'axios';
import { DocumentosPut } from 'services/hooli-services/DocumentosPut';
import { DropZone } from 'components/DropZone';
import { MerchantBOIBUpd } from 'services/hooli-services/MerchantBOIBUpd';
import { ProvinciasGet } from 'services/hooli-services/ProvinciasGet';

export const IngresosBrutos = ({ merchantInfo, load, setLoad, setModalDetails, modalDetails, location }) => {
    console.log("Merchant info", merchantInfo)
    const credentials = useRecoilValue(userCredentials);
    const styles = useStyles();
    const [updFile, setUpdFile] = useState(false);
    const [file, setFile] = useState(null)
    const [provincias, setProvincias] = useState([])

    const handleReadFile = async () => {
        const data = {
            fileName: `${merchantInfo.merchantCUIT.replaceAll('-', '')}_IIBB.pdf`,
            code: "IIBB",
            cuit: merchantInfo.merchantCUIT.replaceAll('-', ''),
            action: "R"
        }
        const response = await MerchantBOFilesDo(credentials, data)
        if (response.status.code === 1 && response.result[0].url) {
            const { data } = await axios.get(response.result[0].url, {
                headers: {
                    'Accept': 'application/pdf',
                    "Content-Type": "application/octet-stream"
                },
                responseType: "blob",
                data: {}
            })
            let urlBlob = window.URL.createObjectURL(new Blob([data]));
            let a = document.createElement('a');
            a.href = urlBlob;
            a.download = `${merchantInfo.merchantCUIT.replaceAll('-', '')}_IIBB.pdf`; // Nombre del archivo
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }

    const handleUpdFile = async () => {
        const data = {
            fileName: `${merchantInfo.merchantCUIT.replaceAll('-', '')}_IIBB.pdf`,
            code: "IIBB",
            cuit: merchantInfo.merchantCUIT.replaceAll('-', ''),
            action: "U"
        }
        const response = await MerchantBOFilesDo(credentials, data)
        if (response.status.code === 1) {
            // console.log("File updated", file[0])
            await DocumentosPut(response.result[0].url, file[0])
            setUpdFile(false)
            setFile(null)
        }
    }

    const dataFormik = {
        regimenIngresosBrutos: {
            label: "Regimen de Ingresos Brutos*",
            placeholder: "",
            options: []
        },
        actividadPrincipal: {
            label: "Actividad principal según la AFIP",
            placeholder: ""
        },
        provinciaIngresosBrutos: {
            label: "Provincia de Ingresos Brutos*",
            placeholder: "",
            options: []
        },
        numeroIngresosBrutos: {
            label: "Número de Ingresos Brutos*",
            placeholder: ""
        }
    }
    const validationSchema = yup.object({
        regimenIngresosBrutos: yup.string().required("Se debe ingresar regimen de ingresos brutos"),
        provinciaIngresosBrutos: yup.object().shape({
            provinciaId: yup.string().required(),
            provinciaNombre: yup.string().required()
        }).nullable(),
        numeroIngresosBrutos: yup.string().required("Se debe ingresar ingresos brutos")
    })
    const formik = useFormik({
        initialValues: {
            regimenIngresosBrutos: merchantInfo.merchantIBCod,
            actividadPrincipal: merchantInfo.actividadAfipDescripcion,
            provinciaIngresosBrutos: merchantInfo.provinciaId,
            numeroIngresosBrutos: merchantInfo.merchantIBNumero
        },
        onSubmit: (values) => {
            setLoad(true)
            MerchantBOIBUpd(credentials, {
                merchantId: merchantInfo.merchantId,
                merchantIBCod: formik.values.regimenIngresosBrutos,
                merchantIBNumero: formik.values.numeroIngresosBrutos,
                merchantProvinciaId: formik.values.provinciaIngresosBrutos === null ? null : Number(formik.values.provinciaIngresosBrutos),
                estadoRegistroCod: "A",
            }, location)
            setLoad(false)
            setModalDetails({ ...modalDetails, isOpen: false })
        },
        validationSchema: validationSchema,
    });
    const handleDecline = async () => {
        setLoad(true)
        const response = await MerchantBOIBUpd(credentials, {
            merchantId: merchantInfo.merchantId,
            merchantIBCod: formik.values.regimenIngresosBrutos,
            merchantIBNumero: formik.values.numeroIngresosBrutos,
            merchantProviciaId: formik.values.provinciaIngresosBrutos,
            estadoRegistroCod: "R"
        }, location)
        setLoad(false)
        setModalDetails({ ...modalDetails, isOpen: false })
    }
    const getProvincias = async () => {
        const response = await ProvinciasGet(credentials)
        setProvincias(response.result)
    }
    useEffect(() => {
        getProvincias()
    }, [])
    return (
        <>
            {updFile ? <div style={{ display: "flex", flexDirection: "column", gap: "8px", alignItems: "center" }}>
                <DropZone file={file} setFile={setFile} />
                <Grid item display={"flex"} flexDirection={"row"} justifyContent={"center"} alignItems={"center"} width={"100%"}>
                    <Button text={"Subir"} onClick={handleUpdFile} />
                    <Button text={"Cancelar"} onClick={() => setUpdFile(false)} />
                </Grid>
            </div> : <><Grid container width={"100%"} padding={"20px 0 50px 0"}>
                <Grid item width={"100%"} display={"flex"} gap={4}>
                    <Box width={"50%"} className={styles.containerActividadFiscal}>
                        <label>Actividad principal según la AFIP</label>
                        <textarea name={"actividadPrincipal"} value={formik.values.actividadPrincipal} disabled={true} />
                    </Box>
                    <Box display={"flex"} flexDirection={"column"} width={"50%"} className={styles.containerInput}>
                        <FormikSelectInput data={dataFormik} name={"regimenIngresosBrutos"} options={[{ name: "Convenio Multilateral", value: "M" }, { name: "Local", value: "L" }]} optionValue={"value"} optionName={"name"} optionKey={"value"} formik={formik} />
                        <FormikSelectInput data={dataFormik} name={"provinciaIngresosBrutos"} formik={formik} options={provincias} optionValue={"provinciaId"} optionName={"provinciaNombre"} optionKey={"provinciaId"} noSelect={true} disabled={formik.values.regimenIngresosBrutos === "M" ? true : false} />
                        <FormikTextInput data={dataFormik} name={"numeroIngresosBrutos"} formik={formik} />
                    </Box>
                    <Grid item md={4} lg={4} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                        <Button text={"Descargar constancia IIBB"} onClick={handleReadFile} />
                        <Button text={"Subir constancia IIBB"} onClick={() => setUpdFile(true)} />
                    </Grid>
                </Grid>
            </Grid>
                <div>
                    <Button text={"Confirmar"} onClick={formik.handleSubmit} disabled={load} />
                    <Button text={"Rechazar"} onClick={handleDecline} disabled={load} />
                    <Button text={"Cerrar"} onClick={() => setModalDetails({ ...modalDetails, confirmationModalOpen: true })} />
                </div></>}
        </>
    )
}
