import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
    root: {
        // opacity: "40%",
        // '& img':{
        //     filter: "brightness(0) saturate(100%) invert(36%) sepia(20%) saturate(1456%) hue-rotate(190deg) brightness(104%) contrast(84%)"
        // }
        // background: "#41404091",
        "&:hover": {
            transform: "scale(1.1)",
            // background: "#414040e1",
        },
    },
    imgComerciosDefault: {
        width: "40px",
        height: "40px",
        filter: "brightness(0) saturate(100%) invert(100%)",
    },
    imgAngleBrackets: {
        width: "16px",
        height: "16px",
        filter: "brightness(0) saturate(100%) invert(100%)",
    },
    title: {
        color: "white",
        fontSize: "15px !important",
        fontWeight: "bold",
    },
    subtitle: {
        fontSize: "12px !important",
        color: "white",
    },
}));
