import React, { useCallback, useEffect, useState } from 'react';
import { Cards } from "../../../components/Cards";
import { Box, Grid, TextareaAutosize, Typography } from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { modalData, routeInfo, snackbarData, userCredentials } from '../../../recoilState/GlobalState';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Button, TransitionModal } from 'components';
import { v4 } from "uuid";
import { ParticularidadesImpositivas } from './particularidadesImpositivas';
import { IngresosBrutos } from './ingresosBrutos';
import { LavadoDeDinero } from './lavadoDeDinero';
import { MerchantBONotaUpd } from 'services/hooli-services/MerchantBONotaUpd';
import { MerchantBODetalleGet } from 'services/hooli-services/MerchantBODetallesGet';
import { MerchantBORegistroGet } from 'services/hooli-services/MerchantBORegistroGet';
import { MerchantBONotaGet } from 'services/hooli-services/MerchantBONotaGet';
import { ContainerIdentificacion } from './identificacion/container';
import { ContainerContacto } from './contacto/container';
import styles from "./Registros.module.scss"
import { ContratoSocial } from './contratoSocial';
import { DesignacionDeAutoridades } from './designacionDeAutoridades';
import { RegistroDeAccionistas } from './registroDeAccionistas';
import { UltimoBalanceYActa } from './UltimoBalanceYActa';
import { REPET } from './repet';
import { MerchantBOIBUpd } from 'services/hooli-services/MerchantBOIBUpd';
import { BenefeiciariosPrincipales } from './beneficiariosPrincipales';
import Apoderados from './apoderados';

/**
 * The `RegistroComercio` component is responsible for rendering the merchant registration page. It fetches and displays the merchant's details, registration records, and notes. It also provides functionality to edit and update the merchant's notes.
 */
function RegistroComercio() {
    const credentials = useRecoilValue(userCredentials);
    const setRoute = useSetRecoilState(routeInfo)
    const [modalDetails, setModalDetails] = useRecoilState(modalData);
    const [detalle, setDetalle] = useState({})
    const [registro, setRegistro] = useState([])
    const [nota, setNota] = useState("")
    const [editArea, setEditArea] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [location, setLocation] = useState({})
    const [load, setLoad] = useState(false)
    const [{ message, severity, open }, setSnackbarInfo] = useRecoilState(snackbarData);
    const merchantId = searchParams.get("merchantId")
    const getLocation = async () => {
        let latitud = sessionStorage.getItem("latitud")
        let longitud = sessionStorage.getItem("longitud")
        setLocation({ latitud, longitud })
    }
    const handleClick = (data) => {
        if (data.title) {
            setModalDetails({
                isOpen: true,
                action: data.title,
                title: data.title
            })
        }
    }
    const handleEditNote = () => {
        setEditArea(true)
    }
    const handleUpdNote = async (data) => {
        // setNota(data)
        let params = {
            merchantNotaMerchantId: merchantId,
            merchantNotasTexto: data,
        }
        await MerchantBONotaUpd(credentials, params)
        setEditArea(false)
    }
    const handleCancelEditNote = () => {
        setEditArea(false)
    }
    const updNote = useCallback((data) => setNota(data), []);
    const getInfo = async () => {
        let detail = await MerchantBODetalleGet(credentials, merchantId)
        let register = await MerchantBORegistroGet(credentials, merchantId)
        let note = await MerchantBONotaGet(credentials, merchantId)
        setDetalle(detail.result[0])
        setRegistro(register.result)
        setNota(note.result[0]?.merchantNotaTexto)
    }
    useEffect(() => {
        if (registro.length === 0) {
            getInfo()
        }
        if (detalle?.merchantCUIT) {
            setRoute({
                wordKey: `${detalle.merchantNombre}, ${detalle.merchantCUIT}`
            })
        }
    }, [detalle])
    useEffect(() => {
        getInfo()
    }, [modalDetails])
    useEffect(() => {
        getLocation()
        setModalDetails({
            isOpen: false,
            action: "",
            title: ""
        })
    }, [])
    return (
        <Grid container height={"92vh"} overflow={"hidden"} paddingBlock={"10px"}>

            <TransitionModal confirmationModal={true} >
                {
                    modalDetails.action === "Identificacion" && <ContainerIdentificacion merchantInfo={detalle} load={load} setLoad={setLoad} setModalDetails={setModalDetails} modalDetails={modalDetails} location={location} />
                }
                {
                    modalDetails.action === "Contacto" && <ContainerContacto merchantInfo={detalle} load={load} setLoad={setLoad} setModalDetails={setModalDetails} modalDetails={modalDetails} location={location} />
                }
                {
                    modalDetails.action === "Ingresos brutos" && <IngresosBrutos merchantInfo={detalle} load={load} setLoad={setLoad} setModalDetails={setModalDetails} modalDetails={modalDetails} location={location} />
                }
                {
                    modalDetails.action === "Lavado de dinero" && <LavadoDeDinero merchantInfo={detalle} load={load} setLoad={setLoad} setModalDetails={setModalDetails} modalDetails={modalDetails} location={location} />
                }
                {
                    modalDetails.action === "Particularidades impositivas" && <ParticularidadesImpositivas merchantInfo={detalle} load={load} setLoad={setLoad} setModalDetails={setModalDetails} modalDetails={modalDetails} location={location} />
                }
                {
                    modalDetails.action === "Contrato social" && <ContratoSocial merchantInfo={detalle} load={load} setLoad={setLoad} setModalDetails={setModalDetails} modalDetails={modalDetails} location={location} />
                }
                {
                    modalDetails.action === "Autoridades" && <DesignacionDeAutoridades merchantInfo={detalle} load={load} setLoad={setLoad} setModalDetails={setModalDetails} modalDetails={modalDetails} location={location} />
                }
                {
                    modalDetails.action === "Accionistas" && <RegistroDeAccionistas merchantInfo={detalle} load={load} setLoad={setLoad} setModalDetails={setModalDetails} modalDetails={modalDetails} location={location} />
                }
                {
                    modalDetails.action === "Balance y aprobacion" && <UltimoBalanceYActa merchantInfo={detalle} load={load} setLoad={setLoad} setModalDetails={setModalDetails} modalDetails={modalDetails} location={location} />
                }
                {
                    modalDetails.action === "Beneficiarios principales" && <BenefeiciariosPrincipales merchantInfo={detalle} load={load} setLoad={setLoad} setModalDetails={setModalDetails} modalDetails={modalDetails} location={location} />
                }
                {
                    modalDetails.action === "REPET" && <REPET merchantInfo={detalle} load={load} setLoad={setLoad} setModalDetails={setModalDetails} modalDetails={modalDetails} location={location} />
                }
                {
                    modalDetails.action === "Apoderados" && <Apoderados merchantInfo={detalle} load={load} setLoad={setLoad} setModalDetails={setModalDetails} modalDetails={modalDetails} location={location} />
                }
                {/* <div>
                    <Button text={"Confirmar"} />
                    <Button text={"Rechazar"} />
                    <Button text={"Cerrar"} onClick={() => setModalDetails({ ...modalDetails, confirmationModalOpen: true })} />
                </div> */}
            </TransitionModal>
            <Grid item md={12} lg={12} display={"flex"} flexDirection={"column"} justifyContent={"space-between"} height={"100%"} >
                <div style={{ height: "30%" }}>
                    <Box width={"100%"} display={"flex"} justifyContent={"center"}   >
                        <Typography variant='h1' fontSize={"20px"} fontWeight={"bold"} sx={{ color: "#06132f" }}>
                            Info del merchant
                        </Typography>
                    </Box>
                    <Grid container width={"100%"} padding={"10px 54px 20px "} marginBottom={"10px"} borderBottom={"1px solid #06132f"}>
                        <Grid item width={"100%"} display={"flex"} gap={4}>
                            <Box display={"flex"} flexDirection={"column"} width={"50%"} >
                                <p className={styles.text}><b>CUIT:</b> {detalle?.merchantCUIT}</p>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"} width={"50%"} >
                                <p className={styles.text}><b>Razon social:</b> {detalle?.merchantRZ}</p>
                            </Box>
                        </Grid>
                        <Grid item width={"100%"} display={"flex"} gap={4}>
                            <Box display={"flex"} flexDirection={"column"} width={"50%"} >
                                <p className={styles.text}><b>Tipo de persona:</b> {detalle?.merchantPFPJLeyenda}</p>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"} width={"50%"} >
                                <p className={styles.text}><b>Nombre:</b> {detalle?.merchantNombre}</p>
                            </Box>
                        </Grid>
                        <Grid item width={"100%"} display={"flex"} gap={4}>
                            <Box display={"flex"} flexDirection={"column"} width={"50%"} >
                                <p className={styles.text}><b>Nombre de usuario:</b> {detalle?.usuarioNombres} {detalle?.usuarioApellidos}</p>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"} width={"50%"} >
                                <p className={styles.text}><b>Contacto:</b> {detalle?.merchantMail}</p>
                            </Box>
                        </Grid>
                        <Grid item width={"100%"} display={"flex"} gap={4}>
                            <Box display={"flex"} flexDirection={"column"} width={"100%"} >
                                <p className={styles.text}><b>Actividad segun AFIP:</b> {detalle?.actividadAfipDescripcion}</p>
                            </Box>
                        </Grid>
                    </Grid>
                </div>
                <Box width={"100%"} display={"flex"} justifyContent={"center"}   >
                    <Typography variant='h1' fontSize={"20px"} fontWeight={"bold"} sx={{ color: "#06132f" }}>
                        Registros del comercio
                    </Typography>
                </Box>
                <Grid item md={12} lg={12} style={{ overflowY: "scroll", height: "30%" }} >
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} gap={2} mt={2} >
                        <Cards registros={registro} handleClick={handleClick} />
                    </Box>
                </Grid>
                <div style={{ height: "30%", display: "flex", borderTop: "1px solid #06132f" }}>
                    <div style={{ display: "flex", flexDirection: "column", width: "100%", paddingBottom: "10px" }}>
                        <Box width={"100%"} display={"flex"} justifyContent={"start"}  >
                            <Typography variant='h1' ml={6} fontSize={"20px"} fontWeight={"bold"} sx={{ color: "#06132f" }}>
                                Notas de seguimiento
                            </Typography>
                        </Box>
                        <Box display={"flex"} mt={1} justifyContent={"center"} flex={"8"} alignItems={"start"}>
                            <TextareaAutosize
                                minRows={5}
                                maxRows={5}
                                placeholder="Escribe algo aquí..."
                                style={{ width: "95%", height: "auto" }}
                                value={nota}
                                onChange={(e) => updNote(e.target.value)}
                                disabled={!editArea}
                            />
                        </Box>
                    </div>
                    <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} flex={"1"} alignItems={"start"} >
                        <Button text={"Editar"} onClick={() => handleEditNote()} disabled={editArea} />
                        <Button text={"Guardar"} disabled={!editArea} onClick={() => handleUpdNote(nota)} />
                        <Button text={"Cancelar"} disabled={!editArea} onClick={() => handleCancelEditNote()} />
                    </Box>
                </div>
            </Grid>
        </Grid >
    )
}

export {
    RegistroComercio
}
